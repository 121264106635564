import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Browser from '../Browser/Browser';
import Footer from '../Navs/Footer';
import Navbar from '../Navs/Header';

const App: React.FC = () => {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_4_ID as string);
    ReactGA.send('pageview');
  }, []);

  return (
    <BrowserRouter>
      <Navbar />
      <Browser />
      <Footer />
    </BrowserRouter>
  );
};

export default App;
