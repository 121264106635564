import GridLoader from 'react-spinners/GridLoader';

export const LoadingLoader = () => {
  return (
    <div className={'overlay'}>
      <div className={'container'} id={'loader'}>
        <GridLoader color={'#556CD6'} loading={true} size={20} />
      </div>
    </div>
  );
};

// export const LoadingLoader = () => {
//   useEffect(() => {
//     document.body.style.overflow = 'hidden';
//     return () => {
//       document.body.style.overflow = 'unset';
//     };
//   }, []);
//
//   return (
//     <div className={'overlay'}>
//       <div className={'container'}>
//         <Circles
//           height="80"
//           width="80"
//           color="#ffffff"
//           ariaLabel="circles-loading"
//           wrapperStyle={{}}
//           wrapperClass=""
//           visible={true}
//         />
//       </div>
//     </div>
//   );
// };
