export const createUrl = (rawRoute: string, params: { [key: string]: string | undefined } | undefined) => {
  let route = rawRoute;
  if (params) {
    Object.keys(params).forEach((key) => {
      const value = params[key];
      route = route.replace(`:${key}`, value || '');
      console.log('key: ', key);
      console.log('value: ', value);
      console.log('route: ', route);
    });
    return route;
  }

  return route;
};
