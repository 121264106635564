import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';

import logo from '../../lib/img/logo.png';
import { contactRoute, homeRoute } from '../Browser/routes';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const scrollToId =
    (id: string, fn?: (event: React.MouseEvent<HTMLElement>) => void) =>
    async (event: React.MouseEvent<HTMLElement>) => {
      if (location.pathname !== '/') {
        navigate(homeRoute() + `#${id}`);
      } else {
        const element = document.getElementById(id);
        if (element) {
          fn?.(event);
          handleCloseNavMenu();
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

  const handleHomeClick = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate(homeRoute());
    }
  };

  const handleContactClick = () => {
    navigate(contactRoute());
  };

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flex: 1, display: { xs: 'none', md: 'flex' } }} justifyContent={'flex-start'}>
            <a href={'https://mnb.solutions'} className="no-link">
              <img
                src={logo}
                alt={'MNB Solutions logo'}
                width={'100%'}
                height={'100%'}
                style={{ display: isMd ? 'initial' : 'none' }}
              />
            </a>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} justifyContent={'flex-end'}>
            <Button color="inherit" onClick={handleHomeClick}>
              Home
            </Button>
            <Button color="inherit" onClick={scrollToId('section-2')}>
              Digital Accessibility
            </Button>
            <Button color="inherit" onClick={scrollToId('section-3')}>
              EAA Countdown
            </Button>
            <Button color="inherit" onClick={scrollToId('section-4')}>
              Path to EAA Compliance
            </Button>
            <Button color="inherit" onClick={scrollToId('section-6')}>
              Compliance Audit
            </Button>
            <Button color="inherit" onClick={scrollToId('section-7')}>
              Achieve Compliance
            </Button>
            <Button color="inherit" onClick={handleContactClick}>
              Contact
            </Button>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            {/*<Menu*/}
            {/*  id="menu-appbar"*/}
            {/*  anchorEl={anchorElNav}*/}
            {/*  anchorOrigin={{*/}
            {/*    vertical: 'bottom',*/}
            {/*    horizontal: 'left'*/}
            {/*  }}*/}
            {/*  keepMounted*/}
            {/*  transformOrigin={{*/}
            {/*    vertical: 'top',*/}
            {/*    horizontal: 'left'*/}
            {/*  }}*/}
            {/*  open={Boolean(anchorElNav)}*/}
            {/*  onClose={handleCloseNavMenu}*/}
            {/*  sx={{ display: { xs: 'block', md: 'none' } }}*/}
            {/*>*/}
            <Drawer open={Boolean(anchorElNav)} onClose={handleCloseNavMenu}>
              <MenuItem onClick={scrollToId('section-1', handleOpenNavMenu)}>Home</MenuItem>
              <MenuItem onClick={scrollToId('section-2', handleOpenNavMenu)}>Digital Accessibility</MenuItem>
              <MenuItem onClick={scrollToId('section-3', handleOpenNavMenu)}>EAA Countdown</MenuItem>
              <MenuItem onClick={scrollToId('section-4', handleOpenNavMenu)}>Path to EAA Compliance</MenuItem>
              <MenuItem onClick={scrollToId('section-6', handleOpenNavMenu)}>Compliance Audit</MenuItem>
              <MenuItem onClick={scrollToId('section-7', handleOpenNavMenu)}>Achieve Compliance</MenuItem>
              <MenuItem onClick={scrollToId('section-9', handleOpenNavMenu)}>Contact</MenuItem>
            </Drawer>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }} justifyContent={'center'}>
            <img src={logo} alt={'MNB Solutions logo'} width={'35%'} style={{ display: isMd ? 'none' : 'initial' }} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
