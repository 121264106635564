import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { privacyPolicyRoute } from '../Browser/routes';

const Footer = () => {
  return (
    <footer className={'footer-bg'} role="contentinfo">
      <Box pt={10} />
      <Grid container justifyContent={'center'} justifyItems={'center'}>
        {/*<LazyShow>*/}
        <Box py={2} />
        <Grid item xs={12} pt={2}>
          <Typography variant={'body1'} textAlign={'center'}>
            © {new Date().getFullYear()}{' '}
            <a href="https://mnb.solutions" className={'link'}>
              MNB solutions
            </a>{' '}
            /{' '}
            <Link to={privacyPolicyRoute()} className={'link'}>
              Privacy Policy
            </Link>
            {' / '}
            <a href={'https://www.linkedin.com/company/mnb-solutions'} className={'link'}>
              LinkedIn
            </a>
            {' / '}
            <a href={'https://www.instagram.com/mnbsolutions/'} className={'link'}>
              Instagram
            </a>
            {' / '}
            <a href={'https://www.facebook.com/MNBSolutions/'} className={'link'}>
              Facebook
            </a>
            {' / '}
            <a href={'https://www.mnb.solutions/contact/'} className={'link'}>
              Imprint
            </a>
          </Typography>
        </Grid>
        {/*</LazyShow>*/}
      </Grid>
    </footer>
  );
};

export default Footer;
