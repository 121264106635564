import { createUrl } from '../../lib/urils';

export const homeRoute = (params?: {}) => {
  return createUrl('/', params);
};

export const reportRoute = (params?: {}) => {
  return createUrl('/report', params);
};

export const privacyPolicyRoute = (params?: {}) => {
  return createUrl('/privacy-policy', params);
};

export const contactRoute = (params?: {}) => {
  return createUrl('/contact', params);
};
