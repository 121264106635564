import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { red } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Palette {
    white: Palette['primary'];
    disabled: Palette['primary'];
  }

  interface PaletteOptions {
    white?: PaletteOptions['primary'];
    disabled?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    white: true;
    disabled: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true;
    disabled: true;
  }
}
declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    white: true;
    disabled: true;
  }
}

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6'
    },
    text: {
      primary: '#FFF',
      secondary: '#666'
    },
    secondary: {
      main: '#19857b'
    },
    error: {
      main: red.A400
    },
    white: {
      main: '#FFF'
    },
    disabled: {
      main: '#666'
    }
  },
  typography: {
    fontFamily: 'Graphit,sans-serif',
    // fontSize: 14,
    body1: {
      color: '#D9E1FF'
    },
    h1: {
      color: '#FFF',
      fontSize: '4rem'
    },
    h2: {
      color: '#FFF'
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#FFF'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          // borderLeft: '1px solid rgba(224, 224, 224, 0.2)!important',
          borderBottom: '1px solid rgba(224, 224, 224, 0.2)!important'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: '#666!important'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#666!important',
            color: '#fff!important'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#292C67'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#292C67'
        }
      }
    }
  }
});

export default responsiveFontSizes(theme);
